<template>
  <div>
    <div v-if="round" class="row">
      <b-card class="col-12" title="Ganhadores">
        <div v-if="round.winners" class="d-flex">
          <div v-for="winner in round.winners" class="winner col">
            <h4 class="text-success">{{winner.prize.name}}</h4>
            <h4>{{winner.user.name}}</h4>
            <h4>{{winner.prize_collected | toCurrency}}</h4>
          </div>
        </div>
      </b-card>
      <b-card class="col-12 col-lg-6" v-bind:title="'Dados da rodada - ' + round.id">        
        <div class="row"><b-button
              type="button"
              variant="danger"
              class="mr-1 m-control"
              @click="MDXreset()"
              >
              <span>Finalizar Jogo</span>
            </b-button>
          </div>
        <div class="row">
          <div class="col-12- col-lg-6">
            <b-list-group>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="UserCheckIcon"
                    size="16"
                  />
                </span> 
                <span>Jogadores: </span><strong>{{round.total_users_in}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="GridIcon"
                    size="16"
                  />
                </span>
                <span>Cartelas em jogo: </span><strong>{{round.total_cards_in}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>Valor da cartela: </span><strong>{{round.card_price | toCurrency}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                  />
                </span>
                <span>Golden Ball:</span>
                <strong class="flex-align-end">{{round.golden_ball}}</strong>
              </b-list-group-item>
            </b-list-group>

          </div>
          <div class="col-12- col-lg-6">
            <b-list-group>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>Acumulado: </span><strong>{{jackpot | toCurrency}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>LINHA: </span><strong>{{(round.final_total_prize * round.first_prize_percentage)/100 | toCurrency}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>LINHA DUPLA: </span><strong>{{(round.final_total_prize * round.second_prize_percentage)/100 | toCurrency}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>BINGO: </span><strong>{{(round.final_total_prize * round.third_prize_percentage)/100 | toCurrency}}</strong>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </b-card>
      <b-card id="number-box" class="col-12 col-lg-6" title="Numeros sorteados">
        <ul id="picked-numbers">
          <li class="picked-number" v-for="n in round.picked_balls">{{n.number}}</li>
        </ul>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard,BRow,BForm, BCol, BFormGroup, BFormInput, BCardText, BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BForm,
    BCardText,
    BButton,
    BListGroup, 
    BListGroupItem,
    BRow, BCol, BFormGroup, BFormInput,
  },
  data(){
    return{
      ballNumber : "",
      round:{
        'winners': false
      },
      jackpot: 0,
      closestCards: false,
    }
  },
  created(){
    this.getCurrentRound();
  },
  methods:{
    getCurrentRound(){
      this.$http.get('/room/round-summary/'+this.$route.params.roundId)
     .then(res => {
       this.round = res.data;
       this.getJackpotPrize();
      })
    },
    getJackpotPrize(){
      this.$http.get('/room/jackpot-prize')
     .then(res => {
       this.jackpot = res.data
      })
    },
    MDXreset(){
      try{
        this.$http.post('/bolillero/reset').then(res => {
          alert("Reset executado")
          console.log(res)
        })
      }catch(e){
        console.log(e)
      }
    },       
  }
}
</script>

<style>
.list-group-item{
  background-color:transparent;
  border:1px solid rgba(255,255,255,0.3);
  justify-content: space-between;
}

#number-box{
  min-height: 640px;
}
#picked-numbers{
    list-style: none;
}
.picked-number{
    font-size: 18px;
    font-weight: bold;
    padding: 10px 10px 10px 12px;
    margin: 5px;
    border-radius: 50px;
    border: 1px solid;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
}
.picked-number:last-child{
  color:yellow;
}

#ball-to-call{
  height: 60px;
    font-size: 45px;
    line-height: 60px;
    color: #fff;
    text-align: center;
    border: 1px solid;
    font-weight: bold;
}
.ball-confirm{
  font-size: 70px;
  font-weight: bold;
  text-align: center;
}

</style>
